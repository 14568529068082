import React from "react";
import { Terms } from "../components";
import Layout from "../components/layout";

const Termspage = () => {
	return <Layout>
		<Terms />
	</Layout>
};

export default Termspage;
